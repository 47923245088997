import { createGlobalStyle } from 'styled-components';

export const lightTheme = {
  background: '#f1f2f6',
  color: '#1e272e',
  text: '#1e272e',
  linkColor: '#ff4757',
  hoverBackground: '#f1f2f6',
};

export const darkTheme = {
  background: '#1e272e',
  color: '#f1f2f6',
  text: '#f1f2f6',
  linkColor: '#ff4757',
  hoverBackground: '#1e272e',
};

export const navBarLightTheme = {
  background: '#dddee5',
  color: '#1e272e',
  text: '#1e272e',
  linkColor: '#ff4757',
  hoverBackground: '#c4c5c9',
};

export const navBarDarkTheme = {
  background: '#19232a',
  color: '#f1f2f6',
  text: '#f1f2f6',
  linkColor: '#ff4757',
  hoverBackground: '#2a3039',
};

export const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${({ theme }) => theme.background};
    color: ${({ theme }) => theme.color};
    transition: background-color 0.3s ease, color 0.3s ease;
  }

  /* Hide scrollbar for all elements while allowing scrolling */
  * {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
  }

  *::-webkit-scrollbar { /* WebKit */
    display: none;
  }
`;
import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import './styles/aboutPage.css';

const FadeInSection = ({ children }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView({ triggerOnce: true });

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={{
        visible: { opacity: 1, y: 0, transition: { duration: 1 } },
        hidden: { opacity: 0, y: 50 },
      }}
    >
      {children}
    </motion.div>
  );
};

function ContactPage() {
  return (
    <div className="AppContainer">
      <header className="AboutPageHeader">
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
          <h1 className="AboutPageName">Renato Liski</h1>
          <p className="AboutPageSubTitle">Programming | Electrical Engineering | Robotics | Computer Vision</p>
        </motion.div>
      </header>

      <FadeInSection>
        <section className="AboutPageSection">
          <h2 className="AboutPageSectionTitle">About Me</h2>
          <div className="AboutPageSeparator" />
          <ul className="AboutPageList">
            <li className="AboutPageListItem">
              I am a dedicated student pursuing a Bachelor of Engineering in Electrical and Automation Engineering, with a bigger lean on the automation part. My studies involve not only mastering automation systems but also engaging in group work and learning to collaborate effectively as part of a group.
            </li>
            <li className="AboutPageListItem">
              Outside of my studies, I enjoy coding, exploring robotics and artificial intelligence concepts. In my free time, I also find pleasure in playing video games, which helps me unwind.
            </li>
          </ul>
        </section>
      </FadeInSection>

      <FadeInSection>
        <section className="AboutPageSection">
          <h2 className="AboutPageSectionTitle">Education</h2>
          <div className="AboutPageSeparator" />
          <ul className="AboutPageList">
            <li className="AboutPageListItem">Mechanical Engineering - Satakunta University of Applied Sciences (2021-2022)</li>
            <li className="AboutPageListItem">Electrical Engineering and Automation, RoboAI Academy - Satakunta University of Applied Sciences (2022-2025)</li>
          </ul>
        </section>
      </FadeInSection>

      <FadeInSection>
        <section className="AboutPageSection">
          <h2 className="AboutPageSectionTitle">Skills</h2>
          <div className="AboutPageSeparator" />
          <ul className="AboutPageList">
            <li className="AboutPageListItem">Computer Vision: OpenCV, MVTEC Halcon</li>
            <li className="AboutPageListItem">Programming Languages: C, C++, Python, JavaScript, Ruby</li>
            <li className="AboutPageListItem">Microcontrollers: Arduino, Raspberry Pi</li>
            <li className="AboutPageListItem">Robotics: Autonomous Systems, Sensors Integration, Logic Programming</li>
            <li className="AboutPageListItem">Electrical Engineering: Circuit Design, PCB Layout</li>
          </ul>
        </section>
      </FadeInSection>

      <FadeInSection>
        <section className="AboutPageSection">
          <h2 className="AboutPageSectionTitle">Projects</h2>
          <div className="AboutPageSeparator" />
          <ul className="AboutPageList">
            <li className="AboutPageListItem">Automated Beer Can Weighing System - Designed and programmed an affordable solution for a brewery to weigh cans of beer, display them on an OLED screen and automatically discard the ones below a specified weight, using an Arduino and pneumatics.</li>
            <li className="AboutPageListItem">Bolt Machine Vision System - Designed and taught a machine vision solution to detect bolts and identify missing bolts or empty bolt holes in steel objects on a factory line, ensuring quality control.</li>
          </ul>
        </section>
      </FadeInSection>

      <FadeInSection>
        <section className="AboutPageSection">
          <h2 className="AboutPageSectionTitle">Contact me</h2>
          <div className="AboutPageSeparator" />
          <p className="AboutPageText">Email: renato.liski@gmail.com</p>
          <p className="AboutPageText">GitHub: <a className="AboutPageContactLink" href="https://github.com/renskursa" target="_blank" rel="noreferrer">https://github.com/renskursa</a></p>
        </section>
      </FadeInSection>
    </div>
  );
}

export default ContactPage;
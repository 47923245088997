import React from 'react';
import './styles/homePage.css'; 

const HomePage = () => {
  return (
    <div className="HomePageContainer">
      <header className="HomePageHeader">
        <h1 className="HomePageTitle">Lorem ipsum dolor sit amet</h1>
        <hr className="HomePageSeparator" />
        <p className="HomePageSubTitle">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla quam velit, vulputate eu pharetra nec, mattis ac neque.
        </p>
      </header>
    </div>
  );
};

export default HomePage;
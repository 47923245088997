import React, { useState, useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import HomePage from './homePage';
import AboutPage from './aboutPage';
import NavBar from './navBar';
import { lightTheme, darkTheme, navBarLightTheme, navBarDarkTheme } from './styles/themes';
import './styles/App.css';

function App() {
  const [theme, setTheme] = useState('light');
  const [themetoggled, setthemetoggled] = useState(false);
  const [showNavBar, setShowNavBar] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme) {
      setTheme(savedTheme);
    }

    const handleScroll = () => {
      setShowNavBar(window.scrollY < 50);
    };
  
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const routeTitles = {
      '/': 'Home',
      '/about': 'About',
    };

    document.title = routeTitles[location.pathname] || 'Home';
  }, [location]);

  useEffect(() => {
    setthemetoggled(false);
  }, []);

  useEffect(() => {
    const root = document.documentElement;
    const currentTheme = theme === 'dark' ? darkTheme : lightTheme;
    const navbarBackground = theme === 'dark' ? navBarDarkTheme.background : navBarLightTheme.background;

    root.style.setProperty('--background', currentTheme.background);
    root.style.setProperty('--color', currentTheme.color);
    root.style.setProperty('--text', currentTheme.text);
    root.style.setProperty('--linkColor', currentTheme.linkColor);
    root.style.setProperty('--site-background', currentTheme.background);
    root.style.setProperty('--navbar-background', navbarBackground);
  }, [theme]);

  const toggleTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);
    setthemetoggled(true);
    localStorage.setItem('theme', newTheme);
  };

  return (
    <div className="App">
      <button className="ThemeToggleButton" onClick={toggleTheme}>
        {theme === 'light' ? '🌙' : '☀️'}
      </button>
      <div className="AppContainer">
           <NavBar show={showNavBar} theme={theme} themeToggled={themetoggled} />
        <div className="ContentContainer">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/about" element={<AboutPage />} />
          </Routes>
        </div>
      </div>
    </div>
  );
}

export default App;